import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { styled, shadows, colors } from "@puzzle/theme";

const Item = styled(ToggleGroupPrimitive.Item, {
  width: "100%",
  padding: "$0h $1h",
  border: "0px",
  borderRadius: "$1",
  background: "transparent",
  borderColor: "transparent",
  color: "$gray300",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "16px",
  textAlign: "center",
  letterSpacing: "0.2px",
  cursor: "pointer",
  outline: "none",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  transition: "all 0.1s ease-in",
  transitionProperty: "background, border, color, box-shadow",

  "&:hover": { backgroundColor: "$mauve700" },
  "&:focus": { backgroundColor: "$mauve600" },
  // TODO moving the background from one tab to another could be nice flair
  "&[data-state=on]": {
    backgroundColor: colors.neutral600,
    color: "$gray50",
    boxShadow: shadows.black24Toblack24BottomBlurSmallComposed,
  },
});

// mauve800
const Root = styled(ToggleGroupPrimitive.Root, {
  display: "grid",
  // easy way to make equal-width columns
  gridAutoColumns: "minmax(0, 1fr)",
  gridAutoFlow: "column",
  backgroundColor: "$mauve800",
  padding: "3px",
  gap: "$1",

  border: "1px solid $gray600",
  borderRadius: "$1",

  variants: {
    size: {
      small: {
        [`${Item}`]: {
          padding: "2px $1h",
        },
      },
    },
    toolbar: {
      true: {
        borderColor: "$mauve500",
        backgroundColor: "$mauve500",
        padding: "1px",
        gap: "2px",
        height: "32px",

        [`${Item}`]: {
          padding: "0 $1h",

          "&[data-state=on]": {
            backgroundColor: "$mauve800",
          },
        },
      },
      false: {},
    },
    darkBackLightFront: {
      true: {
        borderColor: "$rhino800",
        backgroundColor: "$rhino800",
        padding: "1px",
        gap: "2px",
        height: "32px",
        [`${Item}`]: {
          padding: "0 $5",
          "&[data-state=on]": {
            backgroundColor: "$neutral800",
            color: "$white",
            "&:hover": {
              backgroundColor: "$neutral800",
              color: "$white",
            },
          },
          "&:hover": {
            backgroundColor: "$neutral900",
            color: "$white",
          },
        },
      },
      false: {},
    },
  },
});

export const ToggleGroup = {
  Root,
  Item,
};
