/* eslint-disable react/display-name */
import React from "react";

import rgba from "polished/lib/color/rgba";
import { styled, colors } from "@puzzle/theme";
import { External } from "@puzzle/icons";

const InteractiveCellRoot = styled("button", {
  // TODO reset all button elements
  margin: "2px 0",
  fontSize: "inherit",
  lineHeight: "inherit",

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "flex-end",
  justifyItems: "center",
  textDecoration: "none",
  appearance: "none",
  background: "none",
  outline: "none",

  textAlign: "right",
  border: "none",
  padding: "$1",
  minWidth: "100px",
  height: "26px",
  pointerEvents: "all",
  color: "inherit",

  "@media print": {
    color: "$gray300",
  },

  svg: {
    visibility: "hidden",
  },

  compoundVariants: [
    {
      highlight: true,
      open: true,
      css: {
        backgroundColor: "$purple600",
        color: "$neutral100",
        cursor: "help",
      },
    },
    {
      vendorHighlight: true,
      open: true,
      css: {
        backgroundColor: "$yellow300",
        color: "$gray700",
        cursor: "help",
      },
    },

    {
      highlight: false,
      vendorHighlight: false,
      open: true,
      css: {
        backgroundColor: "$green700 !important",
        color: "$black",
      },
    },
  ],

  variants: {
    root: {
      true: {
        "@media print": {
          fontWeight: "$bold",
          color: "$black",
          fontSize: "$bodyL",
        },
      },
    },
    highlight: {
      true: {
        borderRadius: "4px",
        backgroundColor: rgba(colors.purple500, 0.2),
        "&:hover": {
          backgroundColor: "$purple600",
          color: "$neutral100",
        },
      },
      false: {},
    },

    vendorHighlight: {
      true: {
        borderRadius: "4px",
        backgroundColor: colors.yellow800V2,
        color: "$gray200",
        "&:hover": {
          backgroundColor: "$yellow300",
          color: "$gray700",
        },
      },
      false: {},
    },

    // I accidentally overloaded this to mean open OR active
    open: {
      true: {},
      false: {},
    },

    canOpen: {
      true: {
        cursor: "pointer",

        // We use nested tables, so we have to narrow down the active row using direct descendents.
        // The borders in the footer require an extra element around InteractiveCell.
        // We check for direct descendents, or at most one element around InteractiveCell.
        // The last selector is for the breakout popover.
        "tr:hover > td > &, tr:hover > td > * > &, *:hover > * > &": {
          "&:not(:hover):not(:focus)": {
            backgroundColor: rgba(colors.gray50, 0.1),
          },
        },

        "&:hover, &:focus": {
          backgroundColor: rgba(colors.gray50, 0.2),

          svg: {
            visibility: "visible",
          },
        },
      },
      false: {},
    },

    hasChildren: {
      true: {
        cursor: "pointer",
      },
      false: {},
    },

    variant: {
      footer: {
        fontWeight: "$bold",
        color: "$neutral100",
        "@media print": {
          color: "$black",
          fontSize: "$bodyM",
          fontWeight: "$bold",
        },
      },
    },

    external: {
      true: {
        justifyContent: "space-between",
        // enforces minimum amount of space
        gap: "$1",
        cursor: "pointer !important",
      },
      false: {},
    },
  },
});

const InteractiveCell = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof InteractiveCellRoot> & {
    as?: string | (() => React.ReactNode);
    external?: boolean;
    target?: string;
    rel?: string;
  }
>(({ as = "button", children, external, ...props }, ref) => (
  // @ts-expect-error fix type passing
  <InteractiveCellRoot as={as} ref={ref as any} external={external} {...props}>
    {external && <External />}
    {children}
  </InteractiveCellRoot>
));

export default InteractiveCell;
