/* eslint-disable react/display-name */
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  type CSSProperties,
  type ReactNode,
} from "react";
import { useList } from "react-use";
import url, { UrlObject } from "url";

import { useDataTable, createColumnHelper, Row } from "@puzzle/ui";
import { formatMoney, parseAbsolute } from "@puzzle/utils";
import { ChevronRight, ChevronLeft } from "@puzzle/icons";
import { colors, S } from "@puzzle/theme";
import { IconButton } from "ve";
import { Box } from "@puzzle/ve";

import { Route } from "lib/routes";

import { BalanceByReportColumn, DynamicReportType } from "graphql/types";
import {
  ReportBreakoutLineFragment,
  useGetLedgerReportBreakoutByPeriodQuery,
} from "../graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";

import { EnhancedLedgerReportLine } from "../types";
import InteractiveCell from "../Cells/InteractiveCell";
import { ManualTransactionTooltip } from "components/dashboard/Transactions/AsteriskTooltip";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { Link } from "components/common/Link";
import { getPeriod } from "../reportClassificationUtils";

import {
  Container,
  Header,
  SubHeader,
  Title,
  Total,
  EventTable,
  StyledDataTable,
  RowLoading,
} from "./Breakout.styles";

const EVENT_RELATED_ROUTE_MAP: Record<string, Route> = {
  manual_journal_entry: Route.manualJournals,
  historical_journal_entry: Route.manualJournals,
  bill_received: Route.bills,
  bill_voided: Route.bills,
  payroll: Route.payroll,
};
const relatedRouteForEventType = (eventType: string) => {
  return EVENT_RELATED_ROUTE_MAP[eventType] || Route.transactions;
};

const USER_CONFIRMED_COLOR = colors.blue300;
const SYSTEM_ASSIGNED_COLOR = colors.gray600;
const BREAKOUT_TABLE_TEXT = {
  ACCOUNT_LABEL: "Account",
  PREVIOUS_PAGE_LABEL: "Prev",
  NEXT_PAGE_LABEL: "Next",
  EMPTY_TABLE_MESSAGE: "No activity for this period",
  DETAIL_CONFIRMED_STATE: {
    AUTOMATED: "System Suggested Category",
    USER_ASSIGNED: "User Assigned Category",
    USER_RULE_ASSIGNED: "User Rule Assigned Category",
    FINALIZED: "Finalized Category",
    LOCKED: "System Linked Transaction",
    AI_ASSIGNED: "AI suggested category based on user context",
  },
};
const BREAKOUT_TABLE_LOADING_STATE_COUNT = 3;
const BREAKOUT_TABLE_SIZE = 10;
const BUTTON_CHEVRON_SIZE = 18;
const ICON_BUTTON_SIZE = "30px";
const BUTTON_CHEVRON_STYLES = {
  backgroundColor: colors.rhino700,
  borderRadius: S["1"],
  width: ICON_BUTTON_SIZE,
  height: ICON_BUTTON_SIZE,
};
export const BREAKOUT_TEST_IDS = {
  CONTAINER: "breakout-container",
  TOTAL: "breakout-total",
  TABLE: "breakout-table",
  LOADING_BARS: "breakout-table-loading-bar",
};

const EXTERNAL_EVENT_TYPE_LIST = [
  "manual_journal_entry",
  "historical_journal_entry",
  "bill_received",
  "bill_voided",
  "payroll",
];

// TODO: consider adding more events to the allowlist
const LINKABLE_EVENT_TYPE_ALLOWLIST = new Set([
  "bank_transaction",
  "credit_card",
  "manual_journal_entry",
  "historical_journal_entry",
  "bill_received",
  "bill_voided",
  "payment_initiated",
  "payroll",
]);

const columnHelper = createColumnHelper<ReportBreakoutLineFragment>();
const useColumns = () => {
  const { timeZone } = useActiveCompany<true>();
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return useMemo(
    () => [
      columnHelper.accessor((x) => x.metadata.effectiveAt, {
        header: "Date",
        size: 90,
        cell: ({ getValue }) => dateFormatter.format(parseAbsolute(getValue(), timeZone)),
      }),

      columnHelper.accessor("title", {
        size: 180,
        meta: {
          getCellProps: () => ({ overflow: "ellipsis" }),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return <span title={value ?? undefined}>{value}</span>;
        },
      }),

      columnHelper.accessor("balance", {
        header: "Balance",
        size: 100,
        meta: {
          align: "right",
        },
        cell: ({ getValue, row }) => {
          const { transaction } = row.original;

          return (
            <>
              {formatMoney({ currency: "USD", amount: getValue()! })}
              {transaction && (
                <ManualTransactionTooltip
                  integrationType={transaction.integrationType}
                  css={{ color: "$gray300", right: 0 }}
                />
              )}
            </>
          );
        },
      }),
    ],
    [dateFormatter, timeZone]
  );
};

const ACTION_PANEL_WRAPPER_STYLES = { marginTop: S["3"] };
const ACTION_PANEL_STYLES = {
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
  gap: S["1"],
  margin: "0 auto",
  textAlign: "center" as CSSProperties["textAlign"],
};

const LOADING_BARS_WRAPPER_STYLES = { margin: 0, marginBottom: S["2"] };

type TableLoadingBarsProps = {
  barCount: number;
};
const TableLoadingBars = ({ barCount }: TableLoadingBarsProps) => (
  <Box css={LOADING_BARS_WRAPPER_STYLES} data-testid={BREAKOUT_TEST_IDS.LOADING_BARS}>
    {[...Array(barCount)].map((_, i) => (
      <RowLoading key={i} />
    ))}
  </Box>
);

export type BreakoutProps = {
  node: EnhancedLedgerReportLine;
  timePeriod: BalanceByReportColumn;
  reportType: DynamicReportType;
  header?: ReactNode;
  href?: UrlObject;
};

export const Breakout = forwardRef<HTMLDivElement, BreakoutProps>(
  ({ node, timePeriod, header, reportType, href, ...props }, ref) => {
    const { company } = useActiveCompany();
    const companyId = company!.id;
    const [cursorStack, cursorStackActions] = useList<string>();
    const {
      stickyOptions: { view },
    } = useStickyReportContext();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const matchingPeriodFromFilter =
      !timePeriod.dateRange.toInclusive || !timePeriod.dateRange.fromInclusive
        ? undefined
        : {
            end: timePeriod.dateRange.toInclusive,
            start: timePeriod.dateRange.fromInclusive,
            timePeriodKey: timePeriod.columnKey,
          };

    const matchingBalance = node.balanceByColumn.find((b) => b.columnKey === timePeriod.columnKey);

    const { data, loading } = useGetLedgerReportBreakoutByPeriodQuery({
      variables: {
        input: {
          companyId: companyId!,
          pathToken: timePeriod.pathToken!,
          after: cursorStack[cursorStack.length - 1],
          limit: BREAKOUT_TABLE_SIZE,
          timeZone,
          type: reportType,
          interval: getPeriod(matchingPeriodFromFilter),
        },
      },
    });

    const reportData = data?.ledgerReportBreakoutByPeriod;
    const nextCursor = reportData?.nextPageStartingAfter;
    const handleNextPage = useCallback(() => {
      const after = nextCursor;
      if (after) {
        cursorStackActions.push(after);
      }
    }, [cursorStackActions, nextCursor]);
    const handlePreviousPage = useCallback(() => {
      if (cursorStack.length > 0) {
        cursorStackActions.removeAt(cursorStack.length - 1);
      }
    }, [cursorStack.length, cursorStackActions]);

    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (scrollRef.current && !loading) {
        scrollRef.current.scrollTop = 0;
      }
    }, [data, loading]);

    const isRowDisabled = useCallback((row: Row<ReportBreakoutLineFragment>) => {
      const {
        metadata: { externalId, eventType },
      } = row.original;
      if (!externalId || !eventType) {
        // currently all linking happens by externalID, disable those without one
        // safe nav check on eventType
        return true;
      }
      // dont disable events in the allowlist
      return !LINKABLE_EVENT_TYPE_ALLOWLIST.has(eventType);
    }, []);

    const onRowClick = useCallback(
      (row: Row<ReportBreakoutLineFragment>) => {
        const {
          metadata: { externalId, eventType },
        } = row.original;
        // linking is only done on rows with externalID
        if (!externalId) {
          return;
        }

        // event specific navigation overrides
        if (eventType && EXTERNAL_EVENT_TYPE_LIST.includes(eventType)) {
          window.open(`${relatedRouteForEventType(eventType)}/${externalId}`);

          return;
        }

        // by default go to transactions page
        const transactionPath = `${Route.transactions}/${externalId}`;
        // TODO drawer
        window.open(
          href ? url.format(href).replace(Route.transactions, transactionPath) : transactionPath
        );
      },
      [href]
    );

    const totalLinkRef = useRef<HTMLAnchorElement | null>(null);
    const total = useMemo(() => {
      const content = formatMoney(matchingBalance?.balance ?? { amount: "0", currency: "USD" });

      const isFixedAsset =
        view === "accrual" &&
        node.title === "Fixed Assets" &&
        reportType === DynamicReportType.BalanceSheet &&
        node.ledgerCoaKeys.includes("fixed_assets");

      const isAccrualRevenueLine =
        view === "accrual" &&
        reportType === DynamicReportType.ProfitAndLoss &&
        node.lineType === "revenue_source";

      if (href && !isAccrualRevenueLine && !isFixedAsset) {
        return (
          <Link href={url.format(href)}>
            <InteractiveCell canOpen external as="a" ref={totalLinkRef}>
              {content}
            </InteractiveCell>
          </Link>
        );
      }

      return <InteractiveCell>{content}</InteractiveCell>;
    }, [href, matchingBalance?.balance, node, reportType, view]);

    useEffect(() => {
      if (total) {
        // Anchors can't be autofocused
        totalLinkRef.current?.focus();
      }
    }, [total]);

    const tableData = useMemo(() => reportData?.lines || [], [reportData?.lines]);
    const table = useDataTable({
      data: tableData,
      columns: useColumns(),
    });
    const customBody = loading ? (
      <TableLoadingBars barCount={BREAKOUT_TABLE_LOADING_STATE_COUNT} />
    ) : null;

    const hasPagination = cursorStack.length || nextCursor;
    const hasNextPage = nextCursor !== null;
    const nextArrowColor = hasNextPage ? colors.gray200 : colors.gray600;
    const hasPreviousPage = cursorStack.length > 0;
    const prevArrowColor = hasPreviousPage ? colors.gray200 : colors.gray600;

    return (
      <Container data-testid={BREAKOUT_TEST_IDS.CONTAINER} ref={ref} {...props}>
        {header && <Header>{header}</Header>}
        <SubHeader>
          <Title>
            <span>{BREAKOUT_TABLE_TEXT.ACCOUNT_LABEL}</span>
            <span title={node.title}>{node.title}</span>
          </Title>
          <Total data-testid={BREAKOUT_TEST_IDS.TOTAL}>{total}</Total>
        </SubHeader>
        <EventTable data-testid={BREAKOUT_TEST_IDS.TABLE} ref={scrollRef}>
          <StyledDataTable<ReportBreakoutLineFragment>
            density="mini"
            emptyText={BREAKOUT_TABLE_TEXT.EMPTY_TABLE_MESSAGE}
            table={table}
            loading={loading}
            onRowClick={onRowClick}
            customBody={customBody}
            isRowDisabled={isRowDisabled}
          />
        </EventTable>
        {hasPagination && (
          <Box css={ACTION_PANEL_WRAPPER_STYLES}>
            <Box css={ACTION_PANEL_STYLES}>
              <div>
                <IconButton
                  onClick={handlePreviousPage}
                  css={BUTTON_CHEVRON_STYLES}
                  disabled={!hasPreviousPage}
                >
                  <span className="sr-only">{BREAKOUT_TABLE_TEXT.PREVIOUS_PAGE_LABEL}</span>
                  <ChevronLeft size={BUTTON_CHEVRON_SIZE} fill={prevArrowColor} />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={handleNextPage}
                  css={BUTTON_CHEVRON_STYLES}
                  disabled={!hasNextPage}
                >
                  <span className="sr-only">{BREAKOUT_TABLE_TEXT.NEXT_PAGE_LABEL}</span>
                  <ChevronRight size={BUTTON_CHEVRON_SIZE} fill={nextArrowColor} />
                </IconButton>
              </div>
            </Box>
          </Box>
        )}
      </Container>
    );
  }
);
