import last from "lodash/last";
import React, { ComponentProps, useMemo, useState } from "react";
import Link from "next/link";
import Big from "big.js";
import { endOfMonth, today } from "@internationalized/date";

import { Button, StatusRing, Text, StatusTag, IconButton } from "@puzzle/ui";
import { colors } from "@puzzle/theme";
import {
  formatAccountNameWithInstitution,
  formatMoney,
  formatNumber,
  parseCalendarMonth,
  parseDate,
  pluralize,
  toCalendarMonthString,
} from "@puzzle/utils";
import { Route } from "lib/routes";

import { ToggleGroup } from "components/common/ToggleGroup/ToggleGroup";

import { IncompleteReconStatus, MetricView, TransactionStatus } from "./types";

import { StatusCard } from "./StatusCard";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { Arrow, ChevronRight, Confirmed } from "@puzzle/icons";
import Analytics from "lib/analytics/analytics";
import { useAccountChecklistItemRedirects } from "components/dashboard/Checklist/useAccountChecklistItemRedirects";
import { ChecklistItem_AccountChecklistItem_Fragment } from "graphql/fragments/checklistItem.generated";
import { IntervalMetadataFragment } from "graphql/types";
import { Box } from "@puzzle/ve";
import { S } from "@puzzle/theme";

const statusToAction = {
  [TransactionStatus.Uncategorized]: "categorized",
  [TransactionStatus.Unfinalized]: "finalized",
};

type Metrics = IntervalMetadataFragment;

const PercentageRow = ({ status, metrics }: { status: TransactionStatus; metrics: Metrics }) => {
  if (metrics.interval.__typename === "LedgerDateRangeInterval") {
    throw new Error(`Percentage row not implemented for percentageRow component`);
  }
  const periods = metrics.interval.periods;
  const data = status === "Unfinalized" ? metrics.unfinalized : metrics.uncategorized;
  const color = status === "Unfinalized" ? "finalized" : "categorized";
  const completed = data.percentComplete === 100;
  const lowercaseStatus = status.toLowerCase();

  return (
    <StatusCard.Metrics.Item
      css={{ color: "$gray300", fontWeight: "$bold", gap: "$1", justifyContent: "space-between" }}
    >
      <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
        {completed ? (
          <Confirmed size={24} />
        ) : (
          <StatusRing
            values={[data.percentComplete]}
            colors={[color]}
            size={24}
            innerRadius={10}
            outerRadius={20}
            barBackground={colors.mauve800}
          />
        )}
        <div>
          {formatNumber(Big(data.percentComplete).div(100), { style: "percent" })} of dollar volume
          has been {statusToAction[status]}
        </div>
      </Box>
      {!completed && (
        <Link
          href={{
            pathname: Route.transactions,
            query: {
              status: lowercaseStatus,
              start: parseCalendarMonth(periods[0]).toString(),
              end: endOfMonth(parseCalendarMonth(last(periods)!)).toString(),
            },
          }}
          passHref
        >
          <IconButton>
            <ChevronRight />
          </IconButton>
        </Link>
      )}
    </StatusCard.Metrics.Item>
  );
};

const Transactions = ({ status, metrics }: { status: TransactionStatus; metrics: Metrics }) => {
  const { inAmount, outAmount } = useMemo(() => {
    if (status === TransactionStatus.Uncategorized) {
      return metrics.uncategorized;
    } else {
      return metrics.unfinalized;
    }
  }, [metrics, status]);
  const lowercaseStatus = status.toLowerCase();

  return (
    <>
      <PercentageRow metrics={metrics} status={status} />
      <StatusCard.Metrics.Item variant="total">
        <span>Total {lowercaseStatus} money in</span>
        <span>{formatMoney({ currency: "USD", amount: inAmount }, { truncateValue: true })}</span>
      </StatusCard.Metrics.Item>
      <StatusCard.Metrics.Item variant="total">
        <span>Total {lowercaseStatus} money out</span>
        <span>{formatMoney({ currency: "USD", amount: outAmount }, { truncateValue: true })}</span>
      </StatusCard.Metrics.Item>
    </>
  );
};

const CompletedReconAccount = ({ item }: { item: ChecklistItem_AccountChecklistItem_Fragment }) => {
  return (
    <Box css={{ display: "flex", alignItems: "center", height: 50 }}>
      <Confirmed size={20} css={{ width: 100, marginRight: "$1" }} />
      <Box css={{ flexGrow: 1 }}>
        <Text variant="bodyS" color="$gray300">
          {formatAccountNameWithInstitution(item.account)}
        </Text>
      </Box>
    </Box>
  );
};

const IncompleteReconAccount = ({
  item,
  status,
}: {
  item: ChecklistItem_AccountChecklistItem_Fragment;
  status: IncompleteReconStatus;
}) => {
  const { href } = useAccountChecklistItemRedirects(item);
  const difference = item.dataAnomalyReview.context.ledgerMinusBestGuessBalance;
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const reconciledThrough =
    item.account.ledgerAccount?.ledgerReconciliationSummary?.lastReconciled?.statementDate;

  return (
    <Box css={{ display: "flex", alignItems: "center", height: 75 }}>
      <Box css={{ display: "flex", marginRight: S["1"], width: 100, justifyContent: "center" }}>
        <StatusTag>
          {status === IncompleteReconStatus.InProgress ? "In progress" : "Unreconciled"}
        </StatusTag>
      </Box>
      <Box
        css={{
          flexGrow: 1,
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Text variant="bodyS" color="$gray300">
          {formatAccountNameWithInstitution(item.account)}
        </Text>
        <Box css={{ display: "flex", justifyContent: "space-between" }}>
          <Text variant="bodyXS" color="$gray400">
            {`Estimated difference`}
          </Text>
          <Text variant="bodyXS" color="$gray400">
            {formatMoney(difference)}
          </Text>
        </Box>
        {reconciledThrough && (
          <Box css={{ display: "flex", justifyContent: "space-between" }}>
            <Text variant="bodyXS" color="$gray400">
              {`Reconciled through`}
            </Text>
            <Text variant="bodyXS" color="$gray400">
              {dateFormatter.format(parseDate(reconciledThrough))}
            </Text>
          </Box>
        )}
      </Box>
      <Link href={href} passHref>
        <IconButton>
          <ChevronRight />
        </IconButton>
      </Link>
    </Box>
  );
};

const IncompleteStatus = ({ metrics, showRecon }: { metrics: Metrics; showRecon: boolean }) => {
  if (metrics.interval.__typename === "LedgerDateRangeInterval") {
    throw new Error(`Percentage row not implemented for IncompleteStatus component`);
  }
  const periods = metrics.interval.periods;
  const [view, setView] = useState(MetricView.Transactions);
  const monthString = periods[0];
  const dateFormatter = useCompanyDateFormatter({ month: "long" });
  const month = parseCalendarMonth(monthString);

  // todo restore when we can make this query more performant
  // const accountItems = metrics.checklist.items.filter(
  //   (t) => t.__typename === "AccountChecklistItem"
  // ) as ChecklistItem_AccountChecklistItem_Fragment[];
  const accountItems: ChecklistItem_AccountChecklistItem_Fragment[] = useMemo(() => [], []);

  const reconciledItems = useMemo(
    () =>
      accountItems.filter((item) => {
        return !!item.dataAnomalyReview.context.reconciledThrough;
      }),
    [accountItems]
  );

  return (
    <StatusCard.Metrics
      css={{ position: "relative", top: showRecon ? 0 : -16 }}
      header={
        showRecon && (
          <ToggleGroup.Root
            type="single"
            value={view}
            onValueChange={(view) => view && setView(view as MetricView)}
            onClick={(e) => e.stopPropagation()}
          >
            {Object.values(MetricView).map((view) => (
              <ToggleGroup.Item key={view} value={view} title={view}>
                {view}
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        )
      }
      footer={
        showRecon && (
          <Link
            href={{
              pathname: Route.checklist,
              query: {
                month: monthString,
              },
            }}
            passHref
          >
            <Button
              variant="minimal"
              size="small"
              color="primary"
              css={{ display: "flex", alignItems: "center" }}
              onClick={() =>
                Analytics.dashboardReportStatusChecklistClicked({
                  month: toCalendarMonthString(month),
                })
              }
            >
              Go to {dateFormatter.format(month)} accuracy review
              <Arrow color="$greenalpha" css={{ position: "relative", top: 2, left: 2 }} />
            </Button>
          </Link>
        )
      }
    >
      <Box css={{ marginTop: S["1h"], width: "200", height: 230, overflowY: "auto" }}>
        {view === MetricView.Transactions ? (
          <>
            <Transactions status={TransactionStatus.Uncategorized} metrics={metrics} />
            <Transactions status={TransactionStatus.Unfinalized} metrics={metrics} />
          </>
        ) : (
          <>
            <Text variant="bodyS" color="$gray300">
              {`${reconciledItems.length} of ${pluralize(
                accountItems.length,
                "account has",
                "accounts have"
              )} been
              reconciled for ${dateFormatter.format(month)}`}
            </Text>
            {accountItems.map((item) => {
              const isReconciled = reconciledItems.find((i) => i.id === item.id);
              if (isReconciled) {
                return <CompletedReconAccount key={item.id} item={item} />;
              }
              const inProgress =
                item.account.ledgerAccount?.ledgerReconciliationSummary?.active?.id;
              return (
                <IncompleteReconAccount
                  key={item.id}
                  status={
                    inProgress
                      ? IncompleteReconStatus.InProgress
                      : IncompleteReconStatus.Unreconciled
                  }
                  item={item}
                />
              );
            })}
          </>
        )}
      </Box>
    </StatusCard.Metrics>
  );
};

const MetricsCard = ({
  metrics,
  isComplete,
  isInProgress,
  isLocked,
  isSingleMonth,
  ...props
}: Omit<ComponentProps<typeof StatusCard>, "children"> & {
  metrics: Metrics;
  label: string;
  isComplete: boolean;
  isInProgress: boolean;
  isLocked?: boolean;
  // Used to show recon statuses (if true)
  isSingleMonth: boolean;
}) => {
  if (metrics.interval.__typename === "LedgerDateRangeInterval") {
    throw new Error(`Percentage row not implemented for IncompleteStatus component`);
  }
  const periods = metrics.interval.periods;
  const { timeZone } = useActiveCompany<true>();
  const currentMonth = today(timeZone).set({ day: 1 });

  return (
    <StatusCard width={400} side="top" align="center" arrow {...props}>
      {(isInProgress || parseCalendarMonth(periods[0]).compare(currentMonth) >= 0) && isComplete ? (
        <StatusCard.Message
          status="completeAndInProgress"
          title="You're caught up for now"
          label="Please check back as the month progresses"
        />
      ) : isComplete ? (
        <StatusCard.Message
          status="complete"
          title="Way to go!"
          label="100% of dollar volume has been categorized and finalized"
        />
      ) : (
        <IncompleteStatus
          metrics={metrics}
          // showRecon={Boolean(isSingleMonth && metrics.checklist.items.length)}
          showRecon={false}
        />
      )}
    </StatusCard>
  );
};

export default MetricsCard;
